import { 
  BrowserRouter as Router, 
  Routes,
  Route 
} from 'react-router-dom';
import HomePage from './pages/HomePage';
import CaseDonationPage from './pages/CaseDonationPage'
import GeneralDonationPage from './pages/GeneralDonationPage'
import AllCasesPage from './pages/AllCasesPage'
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage/>} Component={HomePage} />
        <Route path="/case-donation/:id" element={<CaseDonationPage/>} Component={CaseDonationPage} />
        <Route path="/general-donation" element={<GeneralDonationPage/>} Component={GeneralDonationPage} />
        <Route path="/all-cases" element={<AllCasesPage/>} Component={AllCasesPage} />
      </Routes>
    </Router>
  );
}

export default App;


