import Header from "../components/Header"
import Footer from "../components/Footer"
import GeneralDonationContent from './../components/GeneralDonationContent'
import StatisticsAndNumbers from './../components/StatisticsAndNumbers'

const GeneralDonationPage = () => {
    return (
        <>
            <Header />
            <GeneralDonationContent />
            <StatisticsAndNumbers />
            <Footer />
        </>
    )
}

export default GeneralDonationPage