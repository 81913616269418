import React from "react";
import style from './Btn.module.css';
import { NavLink } from 'react-router-dom'

const Btn = ({
    text,
    isBlue = false,
    isGreen = false,
    isGray = false,
    width,
    height,
    link,
    onClick
}) => {
    return (
        <>
            {link ?
                <NavLink
                    className={[
                        style.button,
                        isBlue && style.btnBlue,
                        isGreen && style.btnGreen,
                        isGray && style.btnGray,
                    ].join(' ')}
                    style={{
                        width: width,
                        height: height
                    }}
                    to={link}
                >
                    {text}
                </NavLink>
                :
                <button
                    className={[
                        style.button,
                        isBlue && style.btnBlue,
                        isGreen && style.btnGreen,
                        isGray && style.btnGray,
                    ].join(' ')}
                    style={{
                        width: width,
                        height: height
                    }}
                    onClick={onClick}
                >
                    {text}
                </button>
            }
        </>

    )
}

export default Btn