import React, { useState, useEffect } from "react";
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import style from './Cases.module.css';
import CaseCard from './../UI/CaseCard';
import Btn from './../UI/Btn';
import Data from './../../data.json';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const Cases = (props) => {

    const [arrtype, setArrType] = useState('المضافة حديثا')
    const [isOpen, setIsOpen] = useState(false)
    const [casesData, setCasesData] = useState([])
    // const [casesData, setCasesData] = useState(Data)
    const [casesNo, setCasesNo] = useState()
    const location = useLocation()


    useEffect(() => {
        if (location.pathname == '/') {
            setCasesNo(6)
        }
        getCriticalCasesList()
    }, [])

    const getCriticalCasesList = () => {
        let URL = 'https://tarahum.ais-works.com'

        axios.post(`${URL}/api/API/CRITICAL_CASES/CRITICAL_CASES_LIST`, {
            uid: '0fac272f733f7b60685340a79e371467'
        }).then(res => {
            setCasesData(res?.data?.Data)
        })
    }

    return (
        <Container className="my-5">
            <Row className='justify-content-center'>
                <Col md={8} className='text-center'>
                    <p className={style.casesTitle}>ساهم بالتفريج</p>
                    <p className={style.casesPara}>إن عمل منصه محاكم الخير لسداد ديون الغارمين والملاحقين قضائيا يتمحور في بث الأمل في نفوس أفراد تلك الأسر وذلك من خلال توفير و تقديم المساعدات المالية التي تساعدهم في التغلب على أعباء حياتهم اليومية.</p>
                </Col>
            </Row>

            {/* <Row>
                <Col className="d-flex align-items-center">
                    <p className={style.arrTitle}>رتب الحالات حسب</p>
                    <div className={style.dropdownContainer}>
                        <div className={style.arrDropdown} onClick={() => setIsOpen(!isOpen)}>{arrtype}</div>
                        <div className={[style.arrTypesList, isOpen ? style.openList : null].join(' ')}>
                            <p onClick={() => {
                                setIsOpen(false)
                                setArrType('المضافة حديثا')
                            }}>المضافة حديثا</p>
                            <p onClick={() => {
                                setIsOpen(false)
                                setArrType('المضافة حديثا 1')
                            }}
                            >المضافة حديثا 1</p>
                        </div>
                        <span className={style.dropdownArrow}>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="CaretDown">
                                    <path id="Vector" d="M16.692 8.6326L10.442 14.8826C10.384 14.9407 10.3151 14.9868 10.2392 15.0183C10.1633 15.0497 10.082 15.0659 9.99986 15.0659C9.91772 15.0659 9.8364 15.0497 9.76052 15.0183C9.68465 14.9868 9.61572 14.9407 9.55767 14.8826L3.30767 8.6326C3.1904 8.51532 3.12451 8.35626 3.12451 8.19041C3.12451 8.02456 3.1904 7.8655 3.30767 7.74822C3.42495 7.63095 3.58401 7.56506 3.74986 7.56506C3.91571 7.56506 4.07477 7.63095 4.19205 7.74822L9.99986 13.5568L15.8077 7.74822C15.8657 7.69015 15.9347 7.64409 16.0105 7.61266C16.0864 7.58124 16.1677 7.56506 16.2499 7.56506C16.332 7.56506 16.4133 7.58124 16.4892 7.61266C16.565 7.64409 16.634 7.69015 16.692 7.74822C16.7501 7.80629 16.7962 7.87523 16.8276 7.9511C16.859 8.02697 16.8752 8.10829 16.8752 8.19041C16.8752 8.27253 16.859 8.35385 16.8276 8.42972C16.7962 8.50559 16.7501 8.57453 16.692 8.6326Z" fill="#1C1C1C" />
                                </g>
                            </svg>
                        </span>
                        <div className={isOpen ? style.dropdownOverlay : null} onClick={() => setIsOpen(false)}></div>
                    </div>
                </Col>
            </Row> */}

            <Row className='mt-3'>
                {/* {[...Array(6)].map((e, i) =>
                    <Col md={4} sm={6} key={i}>
                        <CaseCard isThreeQuarters={i % 2 == 0 ? true : false} />
                    </Col>)
                } */}

                {casesData?.slice(0, casesNo).map((data, i) =>
                    <Col md={4} sm={6} key={i}>
                        <CaseCard data={data} />
                    </Col>
                )}
            </Row>

            {props.homePage && casesData.length > 6 ?
                <Row className="justify-content-center mt-4">
                    <Col md={4} xs={6}>
                        <Btn
                            text={'المزيد من الحالات'}
                            isGray={true}
                            width='100%'
                            height='3.5rem'
                            link={props.homePage ? "/all-cases" : null}
                        />
                    </Col>
                </Row>
                : null}

        </Container>
    )
}

export default Cases