import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import style from './AboutInitiative.module.css';
import Btn from './../UI/Btn'

const AboutInitiative = () => {
    return (
        <div id="aboutInitiative" className={style.aboutInitiativeContainer}>
            <Container className='py-5'>
                <Row>
                    <Col md={8}>
                        {/* <p className={style.title}>مبادرة فك كربة <br /> الغارمين </p> */}
                        <p className={style.title}>منصه محاكم الخير لسداد ديون <br /> الغارمين والملاحقين قضائيا  </p>
                        <p className={style.para}>إن عمل منصه محاكم الخير لسداد ديون الغارمين والملاحقين قضائيا يتمحور في بث الأمل في نفوس أفراد تلك الأسر وذلك من خلال توفير و تقديم المساعدات المالية التي تساعدهم في التغلب على أعباء حياتهم اليومية</p>
                    </Col>
                    <Col xs={12} className="my-3">
                        {/* <Btn
                            text={'تبرع عام للمبادرة'}
                            isGreen={true}
                            width='12.438rem'
                            height='3.5rem'
                            link={"/general-donation"}
                        /> */}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AboutInitiative