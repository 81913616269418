import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import style from './Slider.module.css'

const Slider = () => {

    return (
        <div className={style.sliderContainer}>
            <Container className={style.content}>
                <Row>
                    <Col xs={12} className={style.title}>
                        <p>منصة محاكم الخير</p>
                    </Col>
                    <Col xs={12} className={style.subTitle}>
                        <p> لفك كربة الغارمين والملاحقين قضائيا </p>
                    </Col>
                    <Col md={9} className={style.parag}>
                        <p>إن عمل منصه محاكم الخير لسداد ديون الغارمين والملاحقين قضائيا يتمحور في بث الأمل في نفوس أفراد تلك الأسر وذلك من خلال توفير و تقديم المساعدات المالية التي تساعدهم في التغلب على أعباء حياتهم اليومية.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={7}>
                        <div className="d-flex">
                            <div className="d-flex flex-column">
                                <span className={style.infoNum}>1089</span>
                                <span className={style.infoTitle}>ملاحقين قضائيا تم التفريج عنهم</span>
                            </div>

                            <div className={style.infoDivider}></div>

                            <div className="d-flex flex-column">
                                <span className={style.infoNum}>2,178</span>
                                <span className={style.infoTitle}>أسر تمت مُساعدتها</span>
                            </div>

                            <div className={style.infoDivider}></div>
                            
                            <div className="d-flex flex-column">
                                <span className={style.infoNum}>112,665,382.54</span>
                                <span className={style.infoTitle}>اجمالي المبالغ الممنوحة</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Slider