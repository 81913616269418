import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import TarahumLogo from './../../assets/images/Tarahum_logo-2 2.png';
import MasterCard from './../../assets/images/MasterCard.png';
import Visa from './../../assets/images/Group 1597884554.png';
import ApplePay from './../../assets/images/ApplePay2.png';
import MhakedAlKheirLogo from './../../assets/images/Layer_12.png'
import MahakmAlkheir from './../../assets/images/mahakmAlkheir.png'
import EmiratesToday from './../../assets/images/emirates-today 1.png'
import logo1 from './../../assets/images/logo 1.png'
import logo2 from './../../assets/images/logo 2.png'
import style from './Footer.module.css';

const Footer = () => {

    return (
        <div className={style.footerContainer}>
            <Container>
                <Row className={style.footerTop}>
                    <Col md={3} className="d-flex flex-column">
                        <img src={TarahumLogo} className={style.tarahumLogo} />
                        {/* <p className={style.para}>هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع.</p> */}
                        <div className={style.socialMediaLinks}>
                            <a href="https://www.facebook.com/tarahumcharity.dubai" target="_blank">
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="24" height="24" fill="white" />
                                        <path d="M16.1554 13L16.608 10.1044H13.7783V8.22531C13.7783 7.43313 14.1736 6.66094 15.4409 6.66094H16.7273V4.19563C16.7273 4.19563 15.56 4 14.4438 4C12.1135 4 10.5903 5.38688 10.5903 7.8975V10.1044H8V13H10.5903V20H13.7783V13H16.1554Z" fill="#72737C" />
                                    </svg>
                                </span>
                            </a>
                            <a href="https://twitter.com/tarahumcharity" target="_blank">
                                <span className={style.twitter}>
                                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="24" height="24" fill="white" />
                                        <path d="M18.3554 8.53296C18.3655 8.688 18.3655 8.84308 18.3655 8.99812C18.3655 13.7272 15.066 19.1762 9.03555 19.1762C7.17767 19.1762 5.45179 18.5892 4 17.5703C4.26397 17.6035 4.51775 17.6146 4.79188 17.6146C6.32485 17.6146 7.73605 17.0498 8.86296 16.0862C7.42133 16.053 6.2132 15.023 5.79694 13.6054C6.00001 13.6386 6.20304 13.6607 6.41626 13.6607C6.71067 13.6607 7.00511 13.6164 7.2792 13.5389C5.77666 13.2067 4.64972 11.7669 4.64972 10.0281V9.98382C5.08625 10.2496 5.59391 10.4157 6.13195 10.4379C5.24869 9.7955 4.67003 8.69908 4.67003 7.45866C4.67003 6.79416 4.83244 6.18503 5.11672 5.65342C6.73095 7.82414 9.15736 9.24173 11.8782 9.39681C11.8274 9.13101 11.7969 8.85416 11.7969 8.57727C11.7969 6.60588 13.2589 5 15.0761 5C16.0203 5 16.8731 5.43193 17.4721 6.12966C18.2132 5.97462 18.9238 5.67558 19.5533 5.26581C19.3096 6.09646 18.7919 6.79419 18.1117 7.23717C18.7716 7.15968 19.4112 6.96028 20 6.68343C19.5534 7.39221 18.995 8.02347 18.3554 8.53296Z" fill="#72737C" />
                                    </svg> */}
                                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                        width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                        preserveAspectRatio="xMidYMid meet">

                                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                            fill="#000000" stroke="none">
                                            <path d="M75 5093 c10 -16 448 -653 973 -1418 525 -765 955 -1395 955 -1400 0
                                                -6 -431 -512 -959 -1126 -527 -613 -965 -1123 -973 -1132 -13 -16 1 -17 206
                                                -17 l220 0 849 987 c467 542 854 988 859 989 6 2 316 -442 689 -986 l679 -990
                                                744 0 c705 0 743 1 734 18 -5 9 -460 672 -1010 1472 -550 800 -1001 1457
                                                -1001 1460 0 4 418 492 929 1085 l928 1080 -215 3 c-177 2 -218 0 -231 -12 -9
                                                -8 -374 -431 -811 -939 -437 -509 -798 -923 -802 -921 -4 3 -296 425 -649 939
                                                l-642 935 -745 0 -744 0 17 -27z m2815 -2529 c855 -1224 1557 -2229 1558
                                                -2235 3 -6 -118 -8 -334 -7 l-338 3 -1558 2228 c-857 1226 -1558 2231 -1558
                                                2233 0 2 152 4 338 4 l337 -1 1555 -2225z" fill="#72737C" />
                                        </g>
                                    </svg>
                                </span>
                            </a>
                            {/* <a href="https://www.youtube.com/" target="_blank">
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="24" height="24" fill="white" />
                                        <path d="M18.8571 4H5.13929C4.51071 4 4 4.49628 4 5.1055V18.2277C4 18.8369 4.51071 19.3332 5.13929 19.3332H18.8571C19.4857 19.3332 20 18.8369 20 18.2277V5.1055C20 4.49628 19.4857 4 18.8571 4ZM8.83571 17.1427H6.46429V9.82525H8.83929V17.1427H8.83571ZM7.65 8.82585C6.88929 8.82585 6.275 8.23374 6.275 7.50815C6.275 6.78257 6.88929 6.19046 7.65 6.19046C8.40714 6.19046 9.025 6.78257 9.025 7.50815C9.025 8.23717 8.41071 8.82585 7.65 8.82585ZM17.725 17.1427H15.3536V13.5833C15.3536 12.7344 15.3357 11.6426 14.1214 11.6426C12.8857 11.6426 12.6964 12.5667 12.6964 13.5216V17.1427H10.325V9.82525H12.6V10.8246H12.6321C12.95 10.2496 13.725 9.64385 14.8786 9.64385C17.2786 9.64385 17.725 11.1601 17.725 13.1315V17.1427Z" fill="#72737C" />
                                    </svg>
                                </span>
                            </a> */}
                            <a href="https://www.instagram.com/tarahumcharity/" target="_blank">
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="24" height="24" fill="white" />
                                        <path d="M12.0018 7.8978C9.73162 7.8978 7.9005 9.72933 7.9005 12C7.9005 14.2707 9.73162 16.1022 12.0018 16.1022C14.2719 16.1022 16.1031 14.2707 16.1031 12C16.1031 9.72933 14.2719 7.8978 12.0018 7.8978ZM12.0018 14.667C10.5347 14.667 9.33542 13.4709 9.33542 12C9.33542 10.5291 10.5312 9.33304 12.0018 9.33304C13.4724 9.33304 14.6682 10.5291 14.6682 12C14.6682 13.4709 13.4688 14.667 12.0018 14.667ZM17.2274 7.73C17.2274 8.26197 16.7991 8.68682 16.2708 8.68682C15.739 8.68682 15.3142 8.2584 15.3142 7.73C15.3142 7.20161 15.7426 6.77318 16.2708 6.77318C16.7991 6.77318 17.2274 7.20161 17.2274 7.73ZM19.9438 8.7011C19.8831 7.41939 19.5904 6.28406 18.6516 5.34866C17.7165 4.41325 16.5814 4.1205 15.2999 4.05623C13.9793 3.98126 10.0207 3.98126 8.70006 4.05623C7.4222 4.11693 6.28712 4.40968 5.34835 5.34509C4.40959 6.28049 4.12047 7.41582 4.05622 8.69753C3.98126 10.0185 3.98126 13.9779 4.05622 15.2989C4.1169 16.5806 4.40959 17.7159 5.34835 18.6513C6.28712 19.5867 7.41863 19.8795 8.70006 19.9438C10.0207 20.0187 13.9793 20.0187 15.2999 19.9438C16.5814 19.8831 17.7165 19.5903 18.6516 18.6513C19.5868 17.7159 19.8795 16.5806 19.9438 15.2989C20.0187 13.9779 20.0187 10.0221 19.9438 8.7011ZM18.2376 16.7163C17.9592 17.416 17.4202 17.9551 16.717 18.2372C15.664 18.6549 13.1654 18.5585 12.0018 18.5585C10.8381 18.5585 8.33597 18.6513 7.28656 18.2372C6.58695 17.9587 6.04796 17.4196 5.76598 16.7163C5.34835 15.6631 5.44473 13.1639 5.44473 12C5.44473 10.8361 5.35192 8.33337 5.76598 7.28372C6.04439 6.58396 6.58338 6.04485 7.28656 5.7628C8.33954 5.34509 10.8381 5.44148 12.0018 5.44148C13.1654 5.44148 15.6676 5.34866 16.717 5.7628C17.4166 6.04128 17.9556 6.58039 18.2376 7.28372C18.6552 8.33694 18.5588 10.8361 18.5588 12C18.5588 13.1639 18.6552 15.6666 18.2376 16.7163Z" fill="#72737C" />
                                    </svg>
                                </span>
                            </a>
                            <a href="https://www.youtube.com/tarahumcharity" target="_blank">
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="24" height="24" fill="white" />
                                        <path d="M19.6657 7.80248C19.4817 7.09298 18.9395 6.53421 18.2511 6.34458C17.0033 6 12 6 12 6C12 6 6.99669 6 5.7489 6.34458C5.06052 6.53424 4.51835 7.09298 4.33434 7.80248C4 9.08848 4 11.7716 4 11.7716C4 11.7716 4 14.4547 4.33434 15.7407C4.51835 16.4502 5.06052 16.9857 5.7489 17.1753C6.99669 17.5199 12 17.5199 12 17.5199C12 17.5199 17.0033 17.5199 18.2511 17.1753C18.9395 16.9857 19.4817 16.4502 19.6657 15.7407C20 14.4547 20 11.7716 20 11.7716C20 11.7716 20 9.08848 19.6657 7.80248ZM10.3636 14.2077V9.33553L14.5454 11.7717L10.3636 14.2077Z" fill="#72737C" />
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </Col>

                    <Col md={3} className="px-md-5 px-3">
                        <p className={style.paymentsMethodsTitle}>وسائل الدفع</p>
                        <div className={style.paymentsMethodsImgs}>
                            <img src={MasterCard} className='object-fit-contain' />
                            <img src={Visa} className='object-fit-contain' />
                            <img src={ApplePay} className='object-fit-contain' />
                        </div>
                    </Col>

                    <Col md={1}></Col>

                    <Col md={3} className="text-center">
                        <img src={MahakmAlkheir} className={style.mhakedAlKheirLogo} width="95" height="53" />
                        <div className={[style.footerDivider, style.imgDivider].join(' ')}></div>
                        <img src={EmiratesToday} className={style.emiratesToday} />
                    </Col>

                    <Col md={2} className="d-flex flex-column align-items-center">
                        <img src={logo1} className={style.logo1} />
                        <p className={style.licenseNo}>PRHCE-003118929</p>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className={style.footerDivider}></div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p className={style.copyRights}>مؤسسة تراحم الخيريه © 2023 .جميع الحقوق محفوظه</p>
                    </Col>
                </Row>
            </Container >
        </div >
    )
}

export default Footer