import { useState } from "react"

const PieIcon = (props) => {

    return (
        <div className="d-flex justify-content-center align-items-start">
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="99" height="170" viewBox="0 0 99 170" fill="none">
                    <path
                        d="M14.5 3.5C14.5 1.84315 15.8436 0.494439 17.4995 0.553248C28.2021 0.933377 38.7446 3.34505 48.562 7.66937C59.2955 12.3972 68.9272 19.3076 76.8441 27.9609C84.7611 36.6142 90.79 46.8209 94.5471 57.9314C98.3042 69.0418 99.7072 80.8128 98.6669 92.4951C97.6266 104.177 94.1658 115.515 88.5046 125.787C82.8434 136.059 75.1058 145.039 65.7844 152.158C56.463 159.276 45.7618 164.376 34.3618 167.133C23.9348 169.654 13.132 170.165 2.53059 168.648C0.89045 168.413 -0.193741 166.848 0.0990295 165.218L2.7716 150.331C3.06436 148.7 4.62319 147.623 6.26601 147.838C13.9782 148.848 21.8195 148.432 29.3964 146.599C37.9463 144.532 45.9722 140.707 52.9633 135.368C59.9544 130.03 65.7575 123.294 70.0034 115.59C74.2493 107.886 76.845 99.383 77.6252 90.6213C78.4054 81.8596 77.3531 73.0314 74.5353 64.6985C71.7175 56.3657 67.1958 48.7107 61.2581 42.2207C55.3204 35.7307 48.0966 30.5479 40.0465 27.002C32.9127 23.8598 25.2684 22.064 17.499 21.696C15.844 21.6176 14.5 20.2819 14.5 18.625V3.5Z"
                        fill={
                            props.state == 'empty' ? '#F1F3F5'
                                : props.state == 'half' ? '#257B72'
                                    : props.state == 'threeQuarters' ? '#257B72'
                                        : props.state == 'full' ? '#257B72'
                                            : null
                        }
                    />
                </svg>
            </span>


            <div
                className="d-flex flex-column align-items-baseline"
                style={{ marginRight: '-10px' }}>
                <span style={{ marginTop: '-1px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="81" height="85" viewBox="0 0 81 85" fill="none">
                        <path
                            d="M3 85.0001C1.34314 85.0001 -0.00555941 83.656 0.0532173 82.0002C0.775895 61.6414 8.83502 42.1888 22.8 27.2648C36.765 12.3408 55.6404 3.00941 75.9063 0.938199C77.5546 0.769741 78.9853 2.02633 79.0951 3.67953L80.0982 18.7712C80.2081 20.4245 78.9556 21.8464 77.3095 22.0343C62.393 23.7373 48.5274 30.6888 38.225 41.6987C27.9226 52.7085 21.9059 67.0046 21.1959 82.0012C21.1176 83.6562 19.7819 85.0001 18.125 85.0001L3 85.0001Z"
                            fill={
                                props.state == 'empty' ? '#F1F3F5'
                                    : props.state == 'half' ? '#F1F3F5'
                                        : props.state == 'threeQuarters' ? '#F1F3F5'
                                            : props.state == 'full' ? '#257B72'
                                                : null
                            }
                        />
                    </svg>
                </span>

                <span style={{ marginTop: '4px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="69" height="79" viewBox="0 0 69 79" fill="none">
                        <path
                            d="M65.232 76.1896C64.8403 77.7995 63.2157 78.7923 61.6207 78.3437C45.0523 73.6838 30.276 64.0662 19.2979 50.7489C8.31986 37.4315 1.69835 21.0916 0.285324 3.93848C0.149299 2.28722 1.4338 0.881889 3.08885 0.804543L18.1974 0.0984879C19.8524 0.0211411 21.2494 1.30145 21.405 2.95099C22.5924 15.5428 27.5244 27.5172 35.5985 37.3117C43.6725 47.1062 54.4858 54.2323 66.6197 57.8004C68.2093 58.2679 69.1995 59.8835 68.8078 61.4934L65.232 76.1896Z"
                            fill={
                                props.state == 'empty' ? '#F1F3F5'
                                    : props.state == 'half' ? '#F1F3F5'
                                        : props.state == 'threeQuarters' ? '#257B72'
                                            : props.state == 'full' ? '#257B72'
                                                : null
                            }
                        // opacity={isFull ? "1" : "0.2"}
                        />
                    </svg>
                </span>
            </div>
        </div>
    )
}


export default PieIcon