import React from "react";
import Header from "./../components/Header";
import Slider from "../components/Slider";
import DonateNow from "../components/DonateNow";
import Cases from "../components/Cases";
import AboutInitiative from "../components/AboutInitiative";
import StatisticsAndNumbers from "../components/StatisticsAndNumbers";
import DonateVia from "../components/DonateVia";
import GivingPartners from "../components/GivingPartners";
import Footer from "./../components/Footer";

const HomePage = () => {

    return (
        <>
            <Header />
            <Slider />
            {/* <DonateNow /> */}
            <Cases homePage={true} />
            <AboutInitiative />
            <StatisticsAndNumbers />
            <DonateVia />
            {/* <GivingPartners /> */}
            <Footer />
        </>
    )
}

export default HomePage