import React, { useEffect, useState } from "react";
import style from './DonateCard.module.css'
import Btn from './../Btn'
import MasterCard from './../../../assets/images/MasterCard.png';
import Visa from './../../../assets/images/Group 1597884554.png';
import ApplePay from './../../../assets/images/ApplePay2.png';
import Katman from './../../../assets/images/katman_1.png';
import IsolationMode from './../../../assets/images/Isolation_Mode.png';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import Data from './../../../data.json';
import loader from './../../../assets/images/loading.gif'
import wallets from './../../../assets/images/wallets.png'
import bankTransfers from './../../../assets/images/bankTransfers.png'
import creditCards from './../../../assets/images/creditCards.png'
import googlePay from './../../../assets/images/googlePay.png'
import applePay from './../../../assets/images/applePay.png'

const DonateCard = ({ showHeader = false }) => {

    const [amount, setAmount] = useState(100)
    // 0 = MasterCard, 1 = Visa, 2 = ApplePay
    const [paymentMethod, setPaymentMethod] = useState(0)
    const [smsIsOpen, setSmsIsOpen] = useState(false)
    const [onMobile, setOnMobile] = useState()
    const [showLoader, setShowLoader] = useState(false)
    const [currentCase, setCurrentCase] = useState({})
    // 1 = sadakah, 2 = zakah
    const [donationType, setDonationType] = useState(2)
    const location = useLocation()
    let { id } = useParams()

    useEffect(() => {
        let deviceWidth = window.innerWidth
        setOnMobile(deviceWidth <= 600 ? true : false)
        setSmsIsOpen(deviceWidth <= 600 ? true : false)
        getCaseData()
    }, [])

    const getCaseData = () => {
        let URL = 'https://tarahum.ais-works.com'

        axios.post(`${URL}/api/API/CRITICAL_CASES/CRITICAL_CASES_LIST_BY_ID`, {
            uid: '0fac272f733f7b60685340a79e371467',
            Critical_Case_Id: id
        }).then(res => {
            setCurrentCase(res?.data?.Data[0])
        })
    }

    const handleInc = () => setAmount(amount + 100)

    const handleDec = () => {
        if (amount == 100) {
            return
        }
        if (amount > 0) {
            setAmount(amount - 100)
        }
    }

    const handleTransferType = val => setPaymentMethod(val)

    const handlePayment = () => {
        if (paymentMethod == 'googlePay' || paymentMethod == 'wallets') {
            handlePaymentByMashreqDubai()
        } else {
            handlePaymentBySmartDubai()
        }
    }

    const handlePaymentBySmartDubai = () => {
        let Payment_URL = 'https://tarahum.ais-works.com'

        setShowLoader(true)
        axios.post(`${Payment_URL}/epay/API/EPAY/GET_PAYMENT_TOKEN`, {
            Token: '65d1e9658ef80e35c93957b5826ba8fd',
            Donor_Id: '',
            Online_Trans_Value: amount,
            Online_Trans_Source: 'Courts of Goodness',
            Online_Trans_Source_URL: location.pathname,
            Lang: 'AR',
            Online_Donation_Type: donationType,
            Donation_Channel_Type_Id: 2,
            Donation_Channel_Id: id,
            Online_Trans_Description: `محاكم الخير / حاله رقم ${currentCase?.Critical_Case_Code} / ${currentCase?.Critical_Case_Name_AR} / صدقات`,
            Service_Name_AR: `محاكم الخير / حاله رقم ${currentCase?.Critical_Case_Code} / ${currentCase?.Critical_Case_Name_AR} / صدقات`,
            Service_Name_EN: `محاكم الخير / حاله رقم ${currentCase?.Critical_Case_Code} / ${currentCase?.Critical_Case_Name_AR} / صدقات`,
        }).then(res => {
            let url = res?.data?.Data?.uriField
            window.location = url
        }).finally(() => setShowLoader(false))
    }

    const handlePaymentByMashreqDubai = () => {
        let Payment_URL = 'https://tarahum.ais-works.com'

        setShowLoader(true)
        axios.post(`${Payment_URL}/epay/API/MASHREQ/EPAY/GET_MASHREQ_PAYMENT_LINK`, {
            Token: '65d1e9658ef80e35c93957b5826ba8fd',
            Donor_Id: '',
            Online_Trans_Value: amount,
            Online_Trans_Source: 'Courts of Goodness',
            Online_Trans_Source_URL: location.pathname,
            Lang: 'AR',
            Online_Donation_Type: donationType,
            Donation_Channel_Type_Id: 2,
            Donation_Channel_Id: id,
            Online_Trans_Description: `محاكم الخير / حاله رقم ${currentCase?.Critical_Case_Code} / ${currentCase?.Critical_Case_Name_AR} / صدقات`,
            Service_Name_AR: `محاكم الخير / حاله رقم ${currentCase?.Critical_Case_Code} / ${currentCase?.Critical_Case_Name_AR} / صدقات`,
            Service_Name_EN: `محاكم الخير / حاله رقم ${currentCase?.Critical_Case_Code} / ${currentCase?.Critical_Case_Name_AR} / صدقات`,
        }).then(res => {
            let url = res?.data?.Data?.uriField
            window.location = url
        }).finally(() => setShowLoader(false))
    }

    return (
        <>
            <div className={style.card}>
                {showHeader ?
                    <div className={style.header}>
                        <p>طرق التبرع</p>
                        <p>إن عمل منصه محاكم الخير لسداد ديون الغارمين والملاحقين قضائيا يتمحور في بث الأمل في نفوس أفراد تلك الأسر </p>
                    </div>
                    : null}

                <div className={style.donationAmountDiv}>
                    <div className='d-flex align-items-center justify-content-between mb-3 flex-wrap'>
                        <p>ادخل قيمة التبرع بالدرهم</p>
                        <span>
                            <img src={MasterCard} />
                            <img src={Visa} />
                            <img src={ApplePay} />
                        </span>
                    </div>

                    <div className={style.inputDiv}>
                        <span className={style.inputIcon} onClick={() => handleInc()}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Frame">
                                    <path id="Vector" d="M20.5122 11.8189H13.4011V4.44446H11.6233V11.8189H4.51221V13.6626H11.6233V21.037H13.4011V13.6626H20.5122V11.8189Z" fill="#030303" fillOpacity="0.99" />
                                </g>
                            </svg>
                        </span>

                        <span>{amount} درهم</span>

                        <span className={style.inputIcon} onClick={() => handleDec()}>
                            <svg width="49" height="46" viewBox="0 0 49 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.9565 21H32.9565C32.9565 21 33.9565 21 33.9565 21.5V23.5C33.9565 23.5 33.9565 24 32.9565 24H16.9565C16.9565 24 15.9565 24 15.9565 23.5V21.5C15.9565 21.5 15.9565 21 16.9565 21Z" fill="#030303" fillOpacity="0.99" />
                            </svg>
                        </span>
                    </div>
                </div>

                <div className={style.donationTypeDiv}>
                    <p>نوع التبرع</p>
                    {/* <p>اختر نوع التبرع</p> */}
                    <div className='d-flex align-items-center justify-content-between mt-3'>
                        <Btn
                            text={'زكاة مال'}
                            isBlue={donationType == 2 && true}
                            isGray={donationType == 1 && true}
                            width={'49%'}
                            height={'3.5rem'}
                            onClick={() => setDonationType(2)}
                        />
                        <Btn
                            text={'صدقات'}
                            isBlue={donationType == 1 && true}
                            isGray={donationType == 2 && true}
                            width={'49%'}
                            height={'3.5rem'}
                            onClick={() => setDonationType(1)}
                        />
                        {/* <Btn text={'صدقات'} isGray={true} width={'100%'} height={'3.5rem'} /> */}
                    </div>
                </div>

                <div className={style.donationWayDiv}>
                    <div>
                        <p>اختر وسيله الدفع</p>
                        <p> هناك حقيقة مثبتة منذ زمن طويل </p>
                    </div>

                    {/* <div className='d-flex align-items-center justify-content-center flex-wrap'>
                        <div className={[style.imgDiv, paymentMethod == 0 ? style.chosen : null].join(' ')} onClick={() => setPaymentMethod(0)}>
                            {paymentMethod == 0 ?
                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.95654 0C8.17651 0 6.43646 0.527841 4.95641 1.51677C3.47637 2.50571 2.32282 3.91131 1.64163 5.55585C0.960443 7.20038 0.782213 9.00998 1.12948 10.7558C1.47675 12.5016 2.33391 14.1053 3.59259 15.364C4.85126 16.6226 6.4549 17.4798 8.20073 17.8271C9.94656 18.1743 11.7562 17.9961 13.4007 17.3149C15.0452 16.6337 16.4508 15.4802 17.4398 14.0001C18.4287 12.5201 18.9565 10.78 18.9565 9C18.9529 6.61417 18.0035 4.32712 16.3165 2.64008C14.6294 0.953049 12.3424 0.00366205 9.95654 0ZM14.4842 7.21384L10.4689 12.5723C10.2769 12.8319 10.0264 13.0424 9.73763 13.1868C9.44889 13.3311 9.13013 13.4053 8.80731 13.4031C8.25667 13.4026 7.72875 13.1835 7.33962 12.7938L5.2627 10.7169C5.07927 10.5201 4.9794 10.2597 4.98415 9.99066C4.9889 9.72163 5.09788 9.46494 5.28815 9.27467C5.47841 9.08441 5.7351 8.97542 6.00413 8.97068C6.27316 8.96593 6.53353 9.06579 6.73039 9.24923L8.53039 11.0769C8.56157 11.1146 8.6007 11.145 8.64498 11.1659C8.68926 11.1867 8.7376 11.1975 8.78655 11.1975C8.83549 11.1975 8.88383 11.1867 8.92811 11.1659C8.97239 11.145 9.01152 11.1146 9.0427 11.0769L12.8227 5.95385C12.9054 5.84475 13.0088 5.75301 13.127 5.68388C13.2452 5.61475 13.3759 5.56957 13.5115 5.55093C13.6471 5.53228 13.7851 5.54054 13.9176 5.57522C14.05 5.60991 14.1744 5.67034 14.2835 5.75308C14.3926 5.83581 14.4843 5.93922 14.5534 6.0574C14.6226 6.17559 14.6677 6.30623 14.6864 6.44188C14.705 6.57752 14.6968 6.71551 14.6621 6.84797C14.6274 6.98042 14.567 7.10474 14.4842 7.21384Z" fill="#7FC07E" />
                                </svg>
                                : null}
                            <img src={MasterCard} />
                        </div>

                        <div className={[style.imgDiv, paymentMethod == 1 ? style.chosen : null].join(' ')} onClick={() => setPaymentMethod(1)}>
                            {paymentMethod == 1 ?
                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.95654 0C8.17651 0 6.43646 0.527841 4.95641 1.51677C3.47637 2.50571 2.32282 3.91131 1.64163 5.55585C0.960443 7.20038 0.782213 9.00998 1.12948 10.7558C1.47675 12.5016 2.33391 14.1053 3.59259 15.364C4.85126 16.6226 6.4549 17.4798 8.20073 17.8271C9.94656 18.1743 11.7562 17.9961 13.4007 17.3149C15.0452 16.6337 16.4508 15.4802 17.4398 14.0001C18.4287 12.5201 18.9565 10.78 18.9565 9C18.9529 6.61417 18.0035 4.32712 16.3165 2.64008C14.6294 0.953049 12.3424 0.00366205 9.95654 0ZM14.4842 7.21384L10.4689 12.5723C10.2769 12.8319 10.0264 13.0424 9.73763 13.1868C9.44889 13.3311 9.13013 13.4053 8.80731 13.4031C8.25667 13.4026 7.72875 13.1835 7.33962 12.7938L5.2627 10.7169C5.07927 10.5201 4.9794 10.2597 4.98415 9.99066C4.9889 9.72163 5.09788 9.46494 5.28815 9.27467C5.47841 9.08441 5.7351 8.97542 6.00413 8.97068C6.27316 8.96593 6.53353 9.06579 6.73039 9.24923L8.53039 11.0769C8.56157 11.1146 8.6007 11.145 8.64498 11.1659C8.68926 11.1867 8.7376 11.1975 8.78655 11.1975C8.83549 11.1975 8.88383 11.1867 8.92811 11.1659C8.97239 11.145 9.01152 11.1146 9.0427 11.0769L12.8227 5.95385C12.9054 5.84475 13.0088 5.75301 13.127 5.68388C13.2452 5.61475 13.3759 5.56957 13.5115 5.55093C13.6471 5.53228 13.7851 5.54054 13.9176 5.57522C14.05 5.60991 14.1744 5.67034 14.2835 5.75308C14.3926 5.83581 14.4843 5.93922 14.5534 6.0574C14.6226 6.17559 14.6677 6.30623 14.6864 6.44188C14.705 6.57752 14.6968 6.71551 14.6621 6.84797C14.6274 6.98042 14.567 7.10474 14.4842 7.21384Z" fill="#7FC07E" />
                                </svg>
                                : null}
                            <img src={Visa} />
                        </div>

                        <div className={[style.imgDiv, paymentMethod == 2 ? style.chosen : null].join(' ')} onClick={() => setPaymentMethod(2)}>
                            {paymentMethod == 2 ?
                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.95654 0C8.17651 0 6.43646 0.527841 4.95641 1.51677C3.47637 2.50571 2.32282 3.91131 1.64163 5.55585C0.960443 7.20038 0.782213 9.00998 1.12948 10.7558C1.47675 12.5016 2.33391 14.1053 3.59259 15.364C4.85126 16.6226 6.4549 17.4798 8.20073 17.8271C9.94656 18.1743 11.7562 17.9961 13.4007 17.3149C15.0452 16.6337 16.4508 15.4802 17.4398 14.0001C18.4287 12.5201 18.9565 10.78 18.9565 9C18.9529 6.61417 18.0035 4.32712 16.3165 2.64008C14.6294 0.953049 12.3424 0.00366205 9.95654 0ZM14.4842 7.21384L10.4689 12.5723C10.2769 12.8319 10.0264 13.0424 9.73763 13.1868C9.44889 13.3311 9.13013 13.4053 8.80731 13.4031C8.25667 13.4026 7.72875 13.1835 7.33962 12.7938L5.2627 10.7169C5.07927 10.5201 4.9794 10.2597 4.98415 9.99066C4.9889 9.72163 5.09788 9.46494 5.28815 9.27467C5.47841 9.08441 5.7351 8.97542 6.00413 8.97068C6.27316 8.96593 6.53353 9.06579 6.73039 9.24923L8.53039 11.0769C8.56157 11.1146 8.6007 11.145 8.64498 11.1659C8.68926 11.1867 8.7376 11.1975 8.78655 11.1975C8.83549 11.1975 8.88383 11.1867 8.92811 11.1659C8.97239 11.145 9.01152 11.1146 9.0427 11.0769L12.8227 5.95385C12.9054 5.84475 13.0088 5.75301 13.127 5.68388C13.2452 5.61475 13.3759 5.56957 13.5115 5.55093C13.6471 5.53228 13.7851 5.54054 13.9176 5.57522C14.05 5.60991 14.1744 5.67034 14.2835 5.75308C14.3926 5.83581 14.4843 5.93922 14.5534 6.0574C14.6226 6.17559 14.6677 6.30623 14.6864 6.44188C14.705 6.57752 14.6968 6.71551 14.6621 6.84797C14.6274 6.98042 14.567 7.10474 14.4842 7.21384Z" fill="#7FC07E" />
                                </svg>
                                : null}
                            <img src={ApplePay} />
                        </div>
                    </div> */}

                    <div className="d-flex flex-column align-items-start px-2">
                        <span className={style.moneyTransOption}>
                            <input
                                className={style.moneyTransRadio}
                                type="radio"
                                id="creditDebit"
                                name="moneyTrans"
                                value="creditDebit"
                                onClick={() => handleTransferType("creditDebit")}
                            />

                            <label
                                className={[style.moneyTransLabel, paymentMethod == 'creditDebit' ? style.selected : null
                                ].join(' ')}
                                htmlFor="creditDebit">
                                <span style={{ position: 'relative' }}>
                                    {paymentMethod == 'creditDebit' ?
                                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.95654 0C8.17651 0 6.43646 0.527841 4.95641 1.51677C3.47637 2.50571 2.32282 3.91131 1.64163 5.55585C0.960443 7.20038 0.782213 9.00998 1.12948 10.7558C1.47675 12.5016 2.33391 14.1053 3.59259 15.364C4.85126 16.6226 6.4549 17.4798 8.20073 17.8271C9.94656 18.1743 11.7562 17.9961 13.4007 17.3149C15.0452 16.6337 16.4508 15.4802 17.4398 14.0001C18.4287 12.5201 18.9565 10.78 18.9565 9C18.9529 6.61417 18.0035 4.32712 16.3165 2.64008C14.6294 0.953049 12.3424 0.00366205 9.95654 0ZM14.4842 7.21384L10.4689 12.5723C10.2769 12.8319 10.0264 13.0424 9.73763 13.1868C9.44889 13.3311 9.13013 13.4053 8.80731 13.4031C8.25667 13.4026 7.72875 13.1835 7.33962 12.7938L5.2627 10.7169C5.07927 10.5201 4.9794 10.2597 4.98415 9.99066C4.9889 9.72163 5.09788 9.46494 5.28815 9.27467C5.47841 9.08441 5.7351 8.97542 6.00413 8.97068C6.27316 8.96593 6.53353 9.06579 6.73039 9.24923L8.53039 11.0769C8.56157 11.1146 8.6007 11.145 8.64498 11.1659C8.68926 11.1867 8.7376 11.1975 8.78655 11.1975C8.83549 11.1975 8.88383 11.1867 8.92811 11.1659C8.97239 11.145 9.01152 11.1146 9.0427 11.0769L12.8227 5.95385C12.9054 5.84475 13.0088 5.75301 13.127 5.68388C13.2452 5.61475 13.3759 5.56957 13.5115 5.55093C13.6471 5.53228 13.7851 5.54054 13.9176 5.57522C14.05 5.60991 14.1744 5.67034 14.2835 5.75308C14.3926 5.83581 14.4843 5.93922 14.5534 6.0574C14.6226 6.17559 14.6677 6.30623 14.6864 6.44188C14.705 6.57752 14.6968 6.71551 14.6621 6.84797C14.6274 6.98042 14.567 7.10474 14.4842 7.21384Z" fill="#7FC07E" />
                                        </svg>
                                        : null}

                                    <img
                                        className={[
                                            style.moneyTransImg,
                                            paymentMethod == 'creditDebit' ? style.selected : null
                                        ].join(' ')}
                                        src={creditCards}
                                    />
                                </span>
                                بطاقه الائتمان / الخصم
                            </label>
                        </span>

                        <span>
                            <input
                                className={style.moneyTransRadio}
                                type="radio"
                                id="bankTransfer"
                                name="moneyTrans"
                                value="bankTransfer"
                                onClick={() => handleTransferType("bankTransfer")}
                            />

                            <label
                                className={[style.moneyTransLabel, paymentMethod == 'bankTransfer' ? style.selected : null
                                ].join(' ')}
                                htmlFor="bankTransfer">
                                <span style={{ position: 'relative' }}>
                                    {paymentMethod == 'bankTransfer' ?
                                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.95654 0C8.17651 0 6.43646 0.527841 4.95641 1.51677C3.47637 2.50571 2.32282 3.91131 1.64163 5.55585C0.960443 7.20038 0.782213 9.00998 1.12948 10.7558C1.47675 12.5016 2.33391 14.1053 3.59259 15.364C4.85126 16.6226 6.4549 17.4798 8.20073 17.8271C9.94656 18.1743 11.7562 17.9961 13.4007 17.3149C15.0452 16.6337 16.4508 15.4802 17.4398 14.0001C18.4287 12.5201 18.9565 10.78 18.9565 9C18.9529 6.61417 18.0035 4.32712 16.3165 2.64008C14.6294 0.953049 12.3424 0.00366205 9.95654 0ZM14.4842 7.21384L10.4689 12.5723C10.2769 12.8319 10.0264 13.0424 9.73763 13.1868C9.44889 13.3311 9.13013 13.4053 8.80731 13.4031C8.25667 13.4026 7.72875 13.1835 7.33962 12.7938L5.2627 10.7169C5.07927 10.5201 4.9794 10.2597 4.98415 9.99066C4.9889 9.72163 5.09788 9.46494 5.28815 9.27467C5.47841 9.08441 5.7351 8.97542 6.00413 8.97068C6.27316 8.96593 6.53353 9.06579 6.73039 9.24923L8.53039 11.0769C8.56157 11.1146 8.6007 11.145 8.64498 11.1659C8.68926 11.1867 8.7376 11.1975 8.78655 11.1975C8.83549 11.1975 8.88383 11.1867 8.92811 11.1659C8.97239 11.145 9.01152 11.1146 9.0427 11.0769L12.8227 5.95385C12.9054 5.84475 13.0088 5.75301 13.127 5.68388C13.2452 5.61475 13.3759 5.56957 13.5115 5.55093C13.6471 5.53228 13.7851 5.54054 13.9176 5.57522C14.05 5.60991 14.1744 5.67034 14.2835 5.75308C14.3926 5.83581 14.4843 5.93922 14.5534 6.0574C14.6226 6.17559 14.6677 6.30623 14.6864 6.44188C14.705 6.57752 14.6968 6.71551 14.6621 6.84797C14.6274 6.98042 14.567 7.10474 14.4842 7.21384Z" fill="#7FC07E" />
                                        </svg>
                                        : null}
                                    <img
                                        className={[
                                            style.moneyTransImg,
                                            paymentMethod == 'bankTransfer' ? style.selected : null
                                        ].join(' ')}
                                        src={bankTransfers}
                                    />
                                </span>
                                تحويل بنكى
                            </label>
                        </span>

                        <span>
                            <input
                                className={[style.moneyTransRadio,].join(' ')}
                                type="radio"
                                id="applePay"
                                name="moneyTrans"
                                value="applePay"
                                onClick={() => handleTransferType("applePay")}
                            />

                            <label
                                className={[style.moneyTransLabel, paymentMethod == 'applePay' ? style.selected : null
                                ].join(' ')}
                                htmlFor="applePay">
                                <span style={{ position: 'relative' }}>
                                    {paymentMethod == 'applePay' ?
                                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.95654 0C8.17651 0 6.43646 0.527841 4.95641 1.51677C3.47637 2.50571 2.32282 3.91131 1.64163 5.55585C0.960443 7.20038 0.782213 9.00998 1.12948 10.7558C1.47675 12.5016 2.33391 14.1053 3.59259 15.364C4.85126 16.6226 6.4549 17.4798 8.20073 17.8271C9.94656 18.1743 11.7562 17.9961 13.4007 17.3149C15.0452 16.6337 16.4508 15.4802 17.4398 14.0001C18.4287 12.5201 18.9565 10.78 18.9565 9C18.9529 6.61417 18.0035 4.32712 16.3165 2.64008C14.6294 0.953049 12.3424 0.00366205 9.95654 0ZM14.4842 7.21384L10.4689 12.5723C10.2769 12.8319 10.0264 13.0424 9.73763 13.1868C9.44889 13.3311 9.13013 13.4053 8.80731 13.4031C8.25667 13.4026 7.72875 13.1835 7.33962 12.7938L5.2627 10.7169C5.07927 10.5201 4.9794 10.2597 4.98415 9.99066C4.9889 9.72163 5.09788 9.46494 5.28815 9.27467C5.47841 9.08441 5.7351 8.97542 6.00413 8.97068C6.27316 8.96593 6.53353 9.06579 6.73039 9.24923L8.53039 11.0769C8.56157 11.1146 8.6007 11.145 8.64498 11.1659C8.68926 11.1867 8.7376 11.1975 8.78655 11.1975C8.83549 11.1975 8.88383 11.1867 8.92811 11.1659C8.97239 11.145 9.01152 11.1146 9.0427 11.0769L12.8227 5.95385C12.9054 5.84475 13.0088 5.75301 13.127 5.68388C13.2452 5.61475 13.3759 5.56957 13.5115 5.55093C13.6471 5.53228 13.7851 5.54054 13.9176 5.57522C14.05 5.60991 14.1744 5.67034 14.2835 5.75308C14.3926 5.83581 14.4843 5.93922 14.5534 6.0574C14.6226 6.17559 14.6677 6.30623 14.6864 6.44188C14.705 6.57752 14.6968 6.71551 14.6621 6.84797C14.6274 6.98042 14.567 7.10474 14.4842 7.21384Z" fill="#7FC07E" />
                                        </svg>
                                        : null}
                                    <img
                                        className={[
                                            style.moneyTransImg,
                                            paymentMethod == 'applePay' ? style.selected : null
                                        ].join(' ')}
                                        src={applePay}
                                    />
                                </span>
                                Apple Pay
                            </label>
                        </span>

                        <span>
                            <input
                                className={style.moneyTransRadio}
                                type="radio"
                                id="googlePay"
                                name="moneyTrans"
                                value="googlePay"
                                onClick={() => handleTransferType("googlePay")}
                            />

                            <label
                                className={[style.moneyTransLabel, paymentMethod == 'googlePay' ? style.selected : null
                                ].join(' ')}
                                htmlFor="googlePay">
                                <span style={{ position: 'relative' }}>
                                    {paymentMethod == 'googlePay' ?
                                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.95654 0C8.17651 0 6.43646 0.527841 4.95641 1.51677C3.47637 2.50571 2.32282 3.91131 1.64163 5.55585C0.960443 7.20038 0.782213 9.00998 1.12948 10.7558C1.47675 12.5016 2.33391 14.1053 3.59259 15.364C4.85126 16.6226 6.4549 17.4798 8.20073 17.8271C9.94656 18.1743 11.7562 17.9961 13.4007 17.3149C15.0452 16.6337 16.4508 15.4802 17.4398 14.0001C18.4287 12.5201 18.9565 10.78 18.9565 9C18.9529 6.61417 18.0035 4.32712 16.3165 2.64008C14.6294 0.953049 12.3424 0.00366205 9.95654 0ZM14.4842 7.21384L10.4689 12.5723C10.2769 12.8319 10.0264 13.0424 9.73763 13.1868C9.44889 13.3311 9.13013 13.4053 8.80731 13.4031C8.25667 13.4026 7.72875 13.1835 7.33962 12.7938L5.2627 10.7169C5.07927 10.5201 4.9794 10.2597 4.98415 9.99066C4.9889 9.72163 5.09788 9.46494 5.28815 9.27467C5.47841 9.08441 5.7351 8.97542 6.00413 8.97068C6.27316 8.96593 6.53353 9.06579 6.73039 9.24923L8.53039 11.0769C8.56157 11.1146 8.6007 11.145 8.64498 11.1659C8.68926 11.1867 8.7376 11.1975 8.78655 11.1975C8.83549 11.1975 8.88383 11.1867 8.92811 11.1659C8.97239 11.145 9.01152 11.1146 9.0427 11.0769L12.8227 5.95385C12.9054 5.84475 13.0088 5.75301 13.127 5.68388C13.2452 5.61475 13.3759 5.56957 13.5115 5.55093C13.6471 5.53228 13.7851 5.54054 13.9176 5.57522C14.05 5.60991 14.1744 5.67034 14.2835 5.75308C14.3926 5.83581 14.4843 5.93922 14.5534 6.0574C14.6226 6.17559 14.6677 6.30623 14.6864 6.44188C14.705 6.57752 14.6968 6.71551 14.6621 6.84797C14.6274 6.98042 14.567 7.10474 14.4842 7.21384Z" fill="#7FC07E" />
                                        </svg>
                                        : null}
                                    <img
                                        className={[
                                            style.moneyTransImg,
                                            paymentMethod == 'googlePay' ? style.selected : null
                                        ].join(' ')}
                                        src={googlePay}
                                    />
                                </span>
                                Samsung / Google Pay
                            </label>
                        </span>

                        <span>
                            <input
                                className={style.moneyTransRadio}
                                type="radio"
                                id="wallets"
                                name="moneyTrans"
                                value="wallets"
                                onClick={() => handleTransferType("wallets")}
                            />

                            <label
                                className={[style.moneyTransLabel, paymentMethod == 'wallets' ? style.selected : null
                                ].join(' ')}
                                htmlFor="wallets">
                                <span style={{ position: 'relative' }}>
                                    {paymentMethod == 'wallets' ?
                                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.95654 0C8.17651 0 6.43646 0.527841 4.95641 1.51677C3.47637 2.50571 2.32282 3.91131 1.64163 5.55585C0.960443 7.20038 0.782213 9.00998 1.12948 10.7558C1.47675 12.5016 2.33391 14.1053 3.59259 15.364C4.85126 16.6226 6.4549 17.4798 8.20073 17.8271C9.94656 18.1743 11.7562 17.9961 13.4007 17.3149C15.0452 16.6337 16.4508 15.4802 17.4398 14.0001C18.4287 12.5201 18.9565 10.78 18.9565 9C18.9529 6.61417 18.0035 4.32712 16.3165 2.64008C14.6294 0.953049 12.3424 0.00366205 9.95654 0ZM14.4842 7.21384L10.4689 12.5723C10.2769 12.8319 10.0264 13.0424 9.73763 13.1868C9.44889 13.3311 9.13013 13.4053 8.80731 13.4031C8.25667 13.4026 7.72875 13.1835 7.33962 12.7938L5.2627 10.7169C5.07927 10.5201 4.9794 10.2597 4.98415 9.99066C4.9889 9.72163 5.09788 9.46494 5.28815 9.27467C5.47841 9.08441 5.7351 8.97542 6.00413 8.97068C6.27316 8.96593 6.53353 9.06579 6.73039 9.24923L8.53039 11.0769C8.56157 11.1146 8.6007 11.145 8.64498 11.1659C8.68926 11.1867 8.7376 11.1975 8.78655 11.1975C8.83549 11.1975 8.88383 11.1867 8.92811 11.1659C8.97239 11.145 9.01152 11.1146 9.0427 11.0769L12.8227 5.95385C12.9054 5.84475 13.0088 5.75301 13.127 5.68388C13.2452 5.61475 13.3759 5.56957 13.5115 5.55093C13.6471 5.53228 13.7851 5.54054 13.9176 5.57522C14.05 5.60991 14.1744 5.67034 14.2835 5.75308C14.3926 5.83581 14.4843 5.93922 14.5534 6.0574C14.6226 6.17559 14.6677 6.30623 14.6864 6.44188C14.705 6.57752 14.6968 6.71551 14.6621 6.84797C14.6274 6.98042 14.567 7.10474 14.4842 7.21384Z" fill="#7FC07E" />
                                        </svg>
                                        : null}
                                    <img
                                        className={[
                                            style.moneyTransImg,
                                            paymentMethod == 'wallets' ? style.selected : null
                                        ].join(' ')}
                                        src={wallets}
                                    />
                                </span>
                                المحافظ الالكترونية و طرق الدفع الاخرى
                            </label>
                        </span>
                    </div>
                </div>

                <Btn
                    text={'ادفع'}
                    isGreen={true}
                    width={'100%'}
                    height={'4rem'}
                    onClick={() => handlePayment()}
                />

                <div className={style.smsDiv}>
                    {onMobile ?
                        <div className={style.smsDivHeader}>
                            <p>تبرع عن طريق رسالة نصية</p>
                            <p>إن عمل منصه محاكم الخير لسداد ديون الغارمين والملاحقين قضائيا يتمحور في بث الأمل في نفوس أفراد تلك الأسر </p>
                        </div>
                        : null}

                    <div
                        className='d-flex align-items-center justify-content-between flex-wrap'
                        onClick={() => setSmsIsOpen(!smsIsOpen)}
                    >
                        {onMobile ?
                            <p>اختر قيمة التبرع</p>
                            : <p>يمكنك التبرع عن طريق ارسال رسالة نصية</p>
                        }

                        <div>
                            <img src={Katman} />
                            <img src={IsolationMode} />

                            {!onMobile ?
                                <span className={[style.arrow, smsIsOpen ? style.open : null].join(' ')}>
                                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="vuesax/linear/arrow-right">
                                            <g id="arrow-right">
                                                <path id="Vector" d="M22.5365 10.0238L15.4731 17.3588C14.639 18.2251 13.274 18.2251 12.4398 17.3588L5.37646 10.0238" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                : null}
                        </div>
                    </div>

                    {onMobile ?
                        <div className={[style.smsAmountsDiv, smsIsOpen ? style.open : null].join(' ')}>
                            <div className="d-flex flex-column mx-1 my-2">
                                <div className={style.code}>
                                    <span>كود 6637</span>
                                </div>
                                <div className={style.derhum}>
                                    <span>10 درهم</span>
                                </div>
                            </div>

                            <div className="d-flex flex-column mx-1 my-2">
                                <div className={style.code}>
                                    <span>كود 7546</span>
                                </div>
                                <div className={style.derhum}>
                                    <span>20 درهم</span>
                                </div>
                            </div>

                            <div className="d-flex flex-column mx-1 my-2">
                                <div className={style.code}>
                                    <span>كود 7639</span>
                                </div>
                                <div className={style.derhum}>
                                    <span>50 درهم</span>
                                </div>
                            </div>

                            <div className="d-flex flex-column mx-1 my-2">
                                <div className={style.code}>
                                    <span>كود 6689</span>
                                </div>
                                <div className={style.derhum}>
                                    <span>100 درهم</span>
                                </div>
                            </div>

                            <div className="d-flex flex-column mx-1 my-2">
                                <div className={style.code}>
                                    <span>كود 6637</span>
                                </div>
                                <div className={style.derhum}>
                                    <span>200 درهم</span>
                                </div>
                            </div>

                            <div className="d-flex flex-column mx-1 my-2">
                                <div className={style.code}>
                                    <span>كود 7865</span>
                                </div>
                                <div className={style.derhum}>
                                    <span>500 درهم</span>
                                </div>
                            </div>

                            <div className="d-flex flex-column mx-1 my-2">
                                <div className={style.code}>
                                    <span>كود 6637</span>
                                </div>
                                <div className={style.derhum}>
                                    <span>1000 درهم</span>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={[style.smsAmountsDiv, smsIsOpen ? style.open : null].join(' ')}>
                            <div className={style.smsAmount}>100 درهم</div>
                            <div className={style.smsAmount}>200 درهم</div>
                            <div className={style.smsAmount}>300 درهم</div>
                            <div className={style.smsAmount}>400 درهم</div>
                            <div className={style.smsAmount}>500 درهم</div>
                            <div className={style.smsAmount}>1000 درهم</div>
                            <div className={style.smsAmount}>2000 درهم</div>
                        </div>
                    }
                </div>
            </div>

            {showLoader ?
                <>
                    <div className={style.loader}>
                        <img src={loader} />
                    </div>
                    <div className={style.overlay}></div>
                </>
                : null}
        </>
    )
}

export default DonateCard