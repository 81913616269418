import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import EmiratesIslamic from './../../assets/images/EI 2.png';
import style from './DonateVia.module.css';

const DonateVia = () => {
    return (
        <div className={style.DonateViaContainer}>
            <Container className="py-4">
                <Row>
                    <Col md={6} className="d-flex flex-column justify-content-center">
                        <p className={style.title}>التبرع عن طريق الحسابات البنكيه</p>
                        <p className={style.subTitle}>مصرف الإمارات الإسلامي - مؤسسة تراحم</p>
                    </Col>

                    <Col md={6}>
                        <div className={style.card}>
                            <Row>
                                <Col md={12} className={[style.DonateViaImg, 'text-center'].join(' ')}>
                                    <img src={EmiratesIslamic} />
                                </Col>

                                <Col md={12} className='d-flex flex-column'>
                                    <label className={style.label}>رقم الحساب</label>
                                    <div className={style.inputContainer}>
                                        <input className={style.input} value='3707248464001' readOnly />
                                        <span className={style.copyIcon}>
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.86">
                                                    <path d="M20 16.125V21.375C20 25.75 18.25 27.5 13.875 27.5H8.625C4.25 27.5 2.5 25.75 2.5 21.375V16.125C2.5 11.75 4.25 10 8.625 10H13.875C18.25 10 20 11.75 20 16.125Z" fill="#538B84" />
                                                    <path d="M21.375 2.5H16.125C12.1747 2.5 10.3745 3.9369 10.0615 7.42556C10.0118 7.98025 10.4674 8.4375 11.0244 8.4375H13.875C19.125 8.4375 21.5625 10.875 21.5625 16.125V18.9756C21.5625 19.5325 22.0197 19.9882 22.5744 19.9384C26.0631 19.6254 27.5 17.8253 27.5 13.875V8.625C27.5 4.25 25.75 2.5 21.375 2.5Z" fill="#538B84" />
                                                </g>
                                            </svg>
                                        </span>
                                    </div>
                                </Col>

                                <Col md={12} className='d-flex flex-column'>
                                    <label className={style.label}>IBAN</label>
                                    <div className={style.inputContainer}>
                                        <input className={style.input} value='AE380340003707248464001' readOnly />
                                        <span className={style.copyIcon}>
                                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.86">
                                                    <path d="M20 16.125V21.375C20 25.75 18.25 27.5 13.875 27.5H8.625C4.25 27.5 2.5 25.75 2.5 21.375V16.125C2.5 11.75 4.25 10 8.625 10H13.875C18.25 10 20 11.75 20 16.125Z" fill="#538B84" />
                                                    <path d="M21.375 2.5H16.125C12.1747 2.5 10.3745 3.9369 10.0615 7.42556C10.0118 7.98025 10.4674 8.4375 11.0244 8.4375H13.875C19.125 8.4375 21.5625 10.875 21.5625 16.125V18.9756C21.5625 19.5325 22.0197 19.9882 22.5744 19.9384C26.0631 19.6254 27.5 17.8253 27.5 13.875V8.625C27.5 4.25 25.75 2.5 21.375 2.5Z" fill="#538B84" />
                                                </g>
                                            </svg>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default DonateVia