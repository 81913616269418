import Header from "../components/Header"
import Footer from "../components/Footer"
import CaseDonationContent from "../components/CaseDonationContent"

const CaseDonationPage = () => {
    return (
        <>
            <Header />
            <CaseDonationContent />
            <Footer />
        </>
    )
}

export default CaseDonationPage