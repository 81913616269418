import React, { useEffect, useState } from "react";
import style from './CaseCard.module.css';
import PieIcon from './PieIcon'
import Btn from './../Btn'
import Modal from 'react-bootstrap/Modal';
import Katman from './../../../assets/images/katman_1.png';
import IsolationMode from './../../../assets/images/Isolation_Mode.png';
import axios from 'axios';

const CaseCard = ({ data }) => {

    const [onMobile, setOnMobile] = useState()
    const [showSMSModal, setShowSMSModal] = useState(false)
    const [state, setState] = useState('empty')
    const [shareIsOpen, setShareIsOpen] = useState(false)
    const [caseData, setCaseData] = useState([])
    let Base_URL = 'https://ramadan.tarahum.ais-works.com'
    let lang = 'AR'
    let nf = new Intl.NumberFormat('en-US');

    useEffect(() => {
        getCaseData()
        let deviceWidth = window.innerWidth
        setOnMobile(deviceWidth <= 600 ? true : false)
    }, [])

    useEffect(() => {
        if ((caseData?.Critical_Case_Donation_Value - caseData?.Critical_Case_Payment_Value) == caseData?.Critical_Case_Donation_Value) {
            setState('empty')
        } else if ((caseData?.Critical_Case_Donation_Value - caseData?.Critical_Case_Payment_Value) == 0) {
            setState('full')
        } else if ((caseData?.Critical_Case_Donation_Value - caseData?.Critical_Case_Payment_Value) <= (caseData?.Critical_Case_Donation_Value / 2)) {
            setState('half')
        } else {
            setState('threeQuarters')
        }
    }, [caseData])

    const getCaseData = () => {
        let URL = 'https://tarahum.ais-works.com'

        axios.post(`${URL}/api/API/CRITICAL_CASES/CRITICAL_CASES_LIST_BY_ID`, {
            uid: '0fac272f733f7b60685340a79e371467',
            Critical_Case_Id: data?.Critical_Case_Id
        }).then(res => {
            setCaseData(res?.data?.Data[0])
        })
    }

    const handleSMSModal = () => {
        if (onMobile) {
            setShowSMSModal(true)
        }
    }

    const handleShareOnFb = () => {
        let url = encodeURIComponent(
            Base_URL + `https://ramadan.tarahum.ais-works.com/index.html?lng=${lang}&id=${caseData?.Critical_Case_Id}`
        );

        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${url}`
        );
    }

    const handleShareOnTwitter = () => {
        let url = encodeURIComponent(
            Base_URL + `/index.html?lng=${lang}&id=${caseData?.Critical_Case_Id}`
        );

        window.open(
            `https://twitter.com/intent/tweet?text=${url}`
        );
    }

    const handleShareOnWhatsapp = () => {
        let url = encodeURIComponent(
            Base_URL + `/index.html?lng=${lang}&id=${caseData?.Critical_Case_Id}`
        );

        window.open(
            `https://api.whatsapp.com/send?text=${url}`
        );
    }

    return (
        <div className={style.caseCard}>
            <div className={style.cardHeader}>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className={style.nameIcon}>
                            <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 2.4333C15 1.40988 15.8327 0.568558 16.8485 0.693225C18.2935 0.870564 19.708 1.25531 21.0465 1.83801C22.9519 2.66745 24.6616 3.87981 26.067 5.39793C27.4724 6.91606 28.5426 8.7067 29.2095 10.6559C29.8765 12.6051 30.1255 14.6702 29.9409 16.7197C29.7562 18.7692 29.1418 20.7583 28.1369 22.5604C27.132 24.3625 25.7584 25.938 24.1037 27.1868C22.449 28.4357 20.5494 29.3304 18.5258 29.814C17.1068 30.1531 15.6487 30.285 14.1976 30.2081C13.1723 30.1538 12.4987 29.1749 12.6822 28.1647C12.8646 27.1603 13.8273 26.5086 14.8481 26.5222C15.7872 26.5347 16.7267 26.431 17.6443 26.2117C19.1621 25.849 20.5868 25.1779 21.8278 24.2413C23.0688 23.3047 24.099 22.123 24.8527 20.7715C25.6064 19.4199 26.0672 17.9281 26.2057 16.391C26.3442 14.8538 26.1574 13.305 25.6572 11.8431C25.1569 10.3812 24.3543 9.03824 23.3003 7.89965C22.2462 6.76105 20.9639 5.85179 19.5349 5.22971C18.673 4.85452 17.7692 4.58875 16.8449 4.4369C15.835 4.27097 15 3.45672 15 2.4333Z" fill="#257B72" />
                                <path opacity="0.3" d="M11.8946 28.0183C11.6508 29.0089 10.6467 29.6272 9.6912 29.2699C7.25739 28.3597 5.09435 26.835 3.42567 24.8344C1.76827 22.8474 0.67025 20.4767 0.224657 17.961C0.0439167 16.9406 0.856157 16.058 1.89136 16.0102C2.92496 15.9625 3.78591 16.7678 4.00927 17.7781C4.38882 19.4948 5.17686 21.1074 6.31925 22.477C7.46765 23.8538 8.92824 24.9301 10.5715 25.6255C11.5182 26.0262 12.1404 27.0201 11.8946 28.0183Z" fill="#538B84" />
                                <path d="M1.875 15.4048C0.839465 15.4048 -0.01236 14.5619 0.119769 13.5348C0.515606 10.4579 1.88127 7.56356 4.04734 5.27581C6.22409 2.97679 9.07291 1.43425 12.1708 0.846282C13.1752 0.655647 14.0608 1.43895 14.1294 2.459C14.1981 3.48129 13.4178 4.35032 12.4199 4.5827C10.2744 5.08231 8.31016 6.19776 6.7855 7.80806C5.27017 9.40852 4.27714 11.4048 3.90953 13.5388C3.73374 14.5593 2.91053 15.4048 1.875 15.4048Z" fill="#257B72" />
                            </svg>
                        </span>
                        <p className={style.caseName}>{caseData?.Critical_Case_Name_AR}</p>
                    </div>

                    <div style={{ position: 'relative' }}>
                        <span className={style.shareIcon} onClick={() => setShareIsOpen(!shareIsOpen)}>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.8096 14C18.2397 14.0018 17.6769 14.1262 17.1592 14.3646C16.6416 14.6031 16.1813 14.9501 15.8096 15.382L10.7176 12.836C10.8403 12.2854 10.8403 11.7146 10.7176 11.164L15.8096 8.618C16.4274 9.31585 17.2706 9.77474 18.192 9.91466C19.1135 10.0546 20.0549 9.86666 20.852 9.38367C21.6492 8.90067 22.2515 8.15323 22.554 7.27165C22.8566 6.39008 22.8401 5.43028 22.5075 4.5596C22.175 3.68891 21.5474 2.96255 20.7341 2.50716C19.9209 2.05178 18.9737 1.89623 18.0575 2.06766C17.1414 2.23908 16.3145 2.7266 15.7209 3.44522C15.1274 4.16384 14.8048 5.06797 14.8096 6C14.8101 6.28117 14.8409 6.56146 14.9016 6.836L9.80959 9.382C9.27576 8.76534 8.56617 8.3266 7.77595 8.12461C6.98573 7.92261 6.15265 7.96702 5.38838 8.25187C4.62411 8.53672 3.96518 9.0484 3.49993 9.71832C3.03468 10.3882 2.78534 11.1844 2.78534 12C2.78534 12.8156 3.03468 13.6118 3.49993 14.2817C3.96518 14.9516 4.62411 15.4633 5.38838 15.7481C6.15265 16.033 6.98573 16.0774 7.77595 15.8754C8.56617 15.6734 9.27576 15.2347 9.80959 14.618L14.9016 17.164C14.8409 17.4385 14.8101 17.7188 14.8096 18C14.8096 18.7911 15.0442 19.5645 15.4837 20.2223C15.9232 20.8801 16.5479 21.3928 17.2789 21.6955C18.0098 21.9983 18.814 22.0775 19.5899 21.9231C20.3659 21.7688 21.0786 21.3878 21.638 20.8284C22.1974 20.269 22.5784 19.5563 22.7327 18.7804C22.8871 18.0044 22.8079 17.2002 22.5051 16.4693C22.2024 15.7384 21.6897 15.1137 21.0319 14.6741C20.3741 14.2346 19.6007 14 18.8096 14Z" fill="#257B72" />
                            </svg>
                        </span>

                        {shareIsOpen ?
                            <>
                                <div className={style.shareDropdown}>
                                    <span className={style.shareIcons} onClick={() => handleShareOnFb()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px"
                                            height="24px">
                                            <path
                                                d="M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2 C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z" />
                                        </svg>
                                    </span>
                                    <span className={style.shareIcons} onClick={() => handleShareOnTwitter()}>
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                            preserveAspectRatio="xMidYMid meet">

                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#000000" stroke="none">
                                                <path d="M75 5093 c10 -16 448 -653 973 -1418 525 -765 955 -1395 955 -1400 0
                                                -6 -431 -512 -959 -1126 -527 -613 -965 -1123 -973 -1132 -13 -16 1 -17 206
                                                -17 l220 0 849 987 c467 542 854 988 859 989 6 2 316 -442 689 -986 l679 -990
                                                744 0 c705 0 743 1 734 18 -5 9 -460 672 -1010 1472 -550 800 -1001 1457
                                                -1001 1460 0 4 418 492 929 1085 l928 1080 -215 3 c-177 2 -218 0 -231 -12 -9
                                                -8 -374 -431 -811 -939 -437 -509 -798 -923 -802 -921 -4 3 -296 425 -649 939
                                                l-642 935 -745 0 -744 0 17 -27z m2815 -2529 c855 -1224 1557 -2229 1558
                                                -2235 3 -6 -118 -8 -334 -7 l-338 3 -1558 2228 c-857 1226 -1558 2231 -1558
                                                2233 0 2 152 4 338 4 l337 -1 1555 -2225z"/>
                                            </g>
                                        </svg>
                                    </span>
                                    <span className={style.shareIcons} onClick={() => handleShareOnWhatsapp()}>
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt"
                                            height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                            preserveAspectRatio="xMidYMid meet">
                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                fill="#000000" stroke="none">
                                                <path d="M2290 4945 c-1101 -136 -1967 -1020 -2081 -2124 -17 -163 -7 -473 21
                                        -630 43 -247 115 -465 227 -688 l56 -112 -167 -611 c-92 -337 -166 -613 -164
                                        -614 2 -2 282 71 623 161 496 131 625 162 645 154 14 -5 79 -34 145 -64 214
                                        -97 439 -161 676 -193 165 -22 454 -22 614 0 526 74 995 306 1365 676 194 193
                                        323 370 445 611 110 217 188 454 232 704 25 146 25 577 0 730 -43 259 -110
                                        466 -223 695 -323 651 -919 1115 -1632 1270 -222 48 -553 63 -782 35z m625
                                        -414 c287 -53 525 -149 760 -306 402 -269 697 -686 814 -1148 86 -340 79 -726
                                        -18 -1053 -158 -528 -533 -973 -1025 -1213 -286 -140 -550 -201 -871 -201
                                        -368 0 -693 89 -1026 281 l-56 32 -368 -97 c-202 -53 -370 -95 -371 -93 -2 2
                                        39 160 92 352 52 192 96 356 96 364 1 9 -25 59 -57 111 -472 768 -348 1780
                                        295 2420 320 318 726 514 1180 570 123 15 429 4 555 -19z">
                                                </path>
                                                <path d="M1641 3658 c-57 -28 -163 -153 -207 -245 -87 -180 -85 -408 5 -618
                                        110 -259 399 -627 684 -871 200 -171 367 -272 612 -368 251 -98 299 -109 465
                                        -109 131 0 152 2 212 25 100 38 175 85 249 158 58 57 70 75 89 135 23 76 37
                                        197 26 239 -5 22 -34 40 -176 111 -260 130 -365 175 -409 175 -37 0 -43 -4
                                        -87 -62 -101 -133 -194 -236 -218 -242 -29 -7 -86 14 -217 80 -213 106 -386
                                        249 -535 440 -81 104 -154 222 -154 250 0 11 38 70 84 130 90 117 116 161 116
                                        194 0 11 -20 66 -43 123 -24 56 -72 172 -107 257 -44 106 -74 165 -96 188
                                        l-32 32 -108 0 c-91 0 -116 -4 -153 -22z"></path>
                                            </g>
                                        </svg>
                                    </span>
                                </div>

                                <div className={style.shareOverlay} onClick={() => setShareIsOpen(!shareIsOpen)}></div>
                            </>
                            : null}
                    </div>
                </div>

                <div className={style.fileInfo}>
                    <div className="d-flex justify-content-between align-items-center">
                        <p>رقم الحالة</p>
                        <p className={style.fileNo}>{caseData?.Critical_Case_Code}</p>
                    </div>
                    <p>{caseData?.Critical_Case_Details_AR}</p>
                </div>
            </div>

            <div className={style.cardBody}>
                <p className={style.debtAmountTitle}>مبلغ الدين</p>
                <p className={style.debtAmount}><span>{nf.format(caseData?.Critical_Case_Donation_Value)}</span> درهم</p>

                <div className={style.pie}>
                    <PieIcon state={state} />
                    <div className={style.pieInfo}>
                        <p>التبرعات</p>
                        <p>{caseData?.Critical_Case_Payment_Value ? nf.format(caseData?.Critical_Case_Payment_Value) : 0}</p>
                    </div>
                </div>

                <div className={style.remainingContainer}>
                    <p className={style.remainingTitle}>المتبقي</p>
                    <p className={style.remainingAmount}><span className="px-2">{nf.format(caseData?.Critical_Case_Donation_Value - caseData?.Critical_Case_Payment_Value)}</span> درهم</p>
                </div>
            </div>

            <div className={style.cardFooter}>
                <Btn
                    isGreen={true}
                    text={'تبرع الان'}
                    width='12.438rem'
                    height='3.5rem'
                    link={`/case-donation/${caseData?.Critical_Case_Id}`}
                />

                <Btn
                    isGreen={true}
                    text={
                        <span style={{ width: '1.875rem', height: '1.875rem' }}>
                            <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Frame" clipPath="url(#clip0_2623_2019)">
                                    <g id="Clip path group">
                                        <mask id="mask0_2623_2019" maskUnits="userSpaceOnUse" x="0" y="0" width="31" height="31">
                                            <g id="a">
                                                <path id="Vector" d="M0.5 0.30957H30.5V30.3096H0.5V0.30957Z" fill="white" />
                                            </g>
                                        </mask>
                                        <g mask="url(#mask0_2623_2019)">
                                            <g id="Group">
                                                <path id="Vector_2" fillRule="evenodd" clipRule="evenodd" d="M16.7502 2.80957C9.31647 2.80957 3.00022 8.24457 3.00022 15.3096C3.00022 17.6158 3.72897 19.7883 4.88772 21.6546L0.866472 25.6758C0.69171 25.8506 0.572702 26.0733 0.524493 26.3158C0.476283 26.5582 0.501037 26.8095 0.595624 27.0379C0.690212 27.2663 0.850386 27.4615 1.0559 27.5988C1.26141 27.7362 1.50303 27.8095 1.75022 27.8096H16.7502C24.184 27.8096 30.5002 22.3746 30.5002 15.3096C30.5002 8.24457 24.184 2.80957 16.7502 2.80957ZM19.2502 12.8096H10.5002V10.3096H19.2502V12.8096ZM10.5002 19.0596H23.0002V16.5596H10.5002V19.0596Z" fill="white" />
                                            </g>
                                        </g>
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="clip0_2623_2019">
                                        <rect width="30" height="30" fill="white" transform="translate(0.5 0.30957)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                    }
                    width='6.063rem'
                    height='3.5rem'
                    link={onMobile ? null : `/case-donation/${caseData?.Critical_Case_Id}`}
                    onClick={() => handleSMSModal()}
                />
            </div>


            <Modal
                show={showSMSModal}
                onHide={() => setShowSMSModal(false)}
                className={style.smsModal}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <p className={style.title}>تبرع عن طريق رسالة نصية</p>
                    <p className={style.para}>إن عمل منصه محاكم الخير لسداد ديون الغارمين والملاحقين قضائيا يتمحور في بث الأمل في نفوس أفراد تلك الأسر </p>

                    <div className={style.smsBody}>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <p className={style.chooseAmountTitle}>اختر قيمة التبرع</p>

                            <div>
                                <img src={Katman} />
                                <img src={IsolationMode} />
                            </div>
                        </div>

                        <div className="d-flex flex-wrap align-items-center justify-content-center">
                            <div className="d-flex flex-column mx-1 my-2">
                                <div className={style.code}>
                                    <span>كود 6637</span>
                                </div>
                                <div className={style.derhum}>
                                    <span>10 درهم</span>
                                </div>
                            </div>

                            <div className="d-flex flex-column mx-1 my-2">
                                <div className={style.code}>
                                    <span>كود 7546</span>
                                </div>
                                <div className={style.derhum}>
                                    <span>20 درهم</span>
                                </div>
                            </div>

                            <div className="d-flex flex-column mx-1 my-2">
                                <div className={style.code}>
                                    <span>كود 7639</span>
                                </div>
                                <div className={style.derhum}>
                                    <span>50 درهم</span>
                                </div>
                            </div>

                            <div className="d-flex flex-column mx-1 my-2">
                                <div className={style.code}>
                                    <span>كود 6689</span>
                                </div>
                                <div className={style.derhum}>
                                    <span>100 درهم</span>
                                </div>
                            </div>

                            <div className="d-flex flex-column mx-1 my-2">
                                <div className={style.code}>
                                    <span>كود 6637</span>
                                </div>
                                <div className={style.derhum}>
                                    <span>200 درهم</span>
                                </div>
                            </div>

                            <div className="d-flex flex-column mx-1 my-2">
                                <div className={style.code}>
                                    <span>كود 7865</span>
                                </div>
                                <div className={style.derhum}>
                                    <span>500 درهم</span>
                                </div>
                            </div>

                            <div className="d-flex flex-column mx-1 my-2">
                                <div className={style.code}>
                                    <span>كود 6637</span>
                                </div>
                                <div className={style.derhum}>
                                    <span>1000 درهم</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CaseCard