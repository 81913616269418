import React, { useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import style from './GeneralDonationContent.module.css'
import DonateCard from './../UI/DonateCard'

const GeneralDonationContent = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className={style.contentDev}>
            <div className={style.imgDev}>
                <Container>
                    <Row>
                        <Col>
                            <p>مبادرة فك كربة <br /> الغارمين  </p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row className="py-5 align-items-center">
                    <Col md={6}>
                        <p className={style.contentTitleGD}>تبرع للصندوق و ساهم <br /> في المبادرة </p>
                        <p className={style.contentParaGD}>إن عمل منصه محاكم الخير لسداد ديون الغارمين والملاحقين قضائيا يتمحور في بث الأمل في نفوس أفراد تلك الأسر وذلك من خلال توفير و تقديم المساعدات المالية التي تساعدهم في التغلب على أعباء حياتهم اليومية</p>
                        <p className={style.contentParaGD}>إن عمل منصه محاكم الخير لسداد ديون الغارمين والملاحقين قضائيا يتمحور في بث الأمل في نفوس أفراد تلك الأسر وذلك من خلال توفير و تقديم المساعدات المالية التي تساعدهم في التغلب على أعباء حياتهم اليومية..إن عمل منصه محاكم الخير لسداد ديون الغارمين والملاحقين قضائياالتي تساعدهم في التغلب على أعباء حياتهم اليومية.</p>
                    </Col>

                    <Col md={6}>
                        <DonateCard />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default GeneralDonationContent