import React, { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import TarahumLogo from './../../assets/images/Tarahum_logo-2 2.png'
import MhakedAlKheirLogo from './../../assets/images/Layer_1.png'
import EmiratesToday from './../../assets/images/emirates-today 1.png'
import dubaiGov from './../../assets/images/dubaiGov.png'
import MahakmAlkheir from './../../assets/images/mahakmAlkheir.png'
import MahakmDubai from './../../assets/images/mahakmDubai.png'
import style from './Header.module.css'
import { useLocation } from 'react-router-dom'

const Header = () => {

    const location = useLocation();
    const [onMobile, setOnMobile] = useState()

    useEffect(() => {
        let deviceWidth = window.innerWidth
        setOnMobile(deviceWidth <= 1130 ? true : false)
    }, [])

    return (
        <Container fluid className={style.headerContainer}>
            <Row className="flex-wrap">
                <Col md={4} className='d-flex align-items-center justify-content-center order-lg-1'>
                    <Row className='flex-nowrap align-items-center'>
                        <Col xs={2}>
                            <img src={MahakmAlkheir} className={style.MahakmAlkheir} />
                        </Col>
                        <Col xs={1}></Col>
                        <Col xs={5}>
                            <a href="/">
                                <img src={TarahumLogo} className={style.logo} />
                            </a>
                        </Col>
                        <Col xs={1}></Col>
                        <Col xs={3}>
                            <img src={EmiratesToday} className={style.emiratesToday} />
                        </Col>
                    </Row>

                    {/* <div className={style.dividerLine}></div> */}
                    {/* <div className={style.dividerLine}></div> */}
                </Col>

                <Col md={4} className="d-flex justify-content-center align-items-center order-md-2 order-3">
                    <div className={style.menuLinks}>
                        {location.pathname == '/' ?
                            <a href="#aboutInitiative">عن المبادرة</a>
                            : null}
                        {location.pathname == '/' || location.pathname == '/general-donation' ?
                            <a href="#statisticsNumbers">احصائيات و ارقام</a>
                            : null}
                    </div>
                </Col>

                <Col md={4} className='order-md-3 order-2 mt-2 mt-md-0'>
                    <Row className='flex-nowrap align-items-center'>
                        <Col xs={5}>
                            <img src={MahakmDubai} className={style.MahakmDubai} />
                        </Col>
                        <Col xs={7}>
                            <img src={dubaiGov} className={style.dubaiGov} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default Header