import Header from "../components/Header"
import Footer from "../components/Footer"
import Cases from "../components/Cases"
import { useEffect } from "react"

const AllCasesPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header />
            <Cases />
            <Footer />
        </>
    )
}

export default AllCasesPage