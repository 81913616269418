import React, { useEffect, useState } from "react";
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import style from './CaseDonationContent.module.css'
import DonateCard from './../UI/DonateCard'
import CaseCard from './../UI/CaseCard'
import Data from './../../data.json';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const CaseDonationContent = () => {

    const [onMobile, setOnMobile] = useState(false)
    // const [cases, setCases] = useState(Data)
    const [cases, setCases] = useState([])
    const [currentCase, setCurrentCase] = useState()
    const [loaderWidth, setLoaderWidth] = useState('0%')
    const [shareIsOpen, setShareIsOpen] = useState(false)
    let Base_URL = 'https://ramadan.tarahum.ais-works.com'
    let lang = 'AR'
    let { id } = useParams()
    let nf = new Intl.NumberFormat('en-US');

    useEffect(() => {
        let deviceWidth = window.innerWidth
        setOnMobile(deviceWidth <= 600 ? true : false)
        getCriticalCasesList()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id])

    useEffect(() => {
        let totalAmount = currentCase?.Critical_Case_Donation_Value,
            paidAmount = currentCase?.Critical_Case_Payment_Value,
            remainingAmound = totalAmount - paidAmount,
            shadowWidth = (totalAmount - remainingAmound) * 100
        setLoaderWidth(`${shadowWidth}%`)
    }, [currentCase])

    const getCriticalCasesList = () => {
        let URL = 'https://tarahum.ais-works.com'

        axios.post(`${URL}/api/API/CRITICAL_CASES/CRITICAL_CASES_LIST`, {
            uid: '0fac272f733f7b60685340a79e371467'
        }).then(res => {
            setCases(res?.data?.Data)
            getCaseData()
        })
    }

    const getCaseData = () => {
        let URL = 'https://tarahum.ais-works.com'

        axios.post(`${URL}/api/API/CRITICAL_CASES/CRITICAL_CASES_LIST_BY_ID`, {
            uid: '0fac272f733f7b60685340a79e371467',
            Critical_Case_Id: id
        }).then(res => {
            setCurrentCase(res?.data?.Data[0])
        })
    }

    const reduceCases = (acc, cur, index) => {
        const groupIndex = Math.floor(index / 3)
        if (!acc[groupIndex]) acc[groupIndex] = []
        acc[groupIndex].push(cur)
        return acc
    }

    const handleShareOnFb = () => {
        let url = encodeURIComponent(
            Base_URL + `https://ramadan.tarahum.ais-works.com/index.html?lng=${lang}&id=${currentCase?.Critical_Case_Id}`
        );

        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${url}`
        );
    }

    const handleShareOnTwitter = () => {
        let url = encodeURIComponent(
            Base_URL + `/index.html?lng=${lang}&id=${currentCase?.Critical_Case_Id}`
        );

        window.open(
            `https://twitter.com/intent/tweet?text=${url}`
        );
    }

    const handleShareOnWhatsapp = () => {
        let url = encodeURIComponent(
            Base_URL + `/index.html?lng=${lang}&id=${currentCase?.Critical_Case_Id}`
        );

        window.open(
            `https://api.whatsapp.com/send?text=${url}`
        );
    }

    return (
        <>
            <div className={style.imgDev}>
                <p>
                    <span>الرئيسية</span>
                    <span>
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="vuesax/linear/arrow-right">
                                <g id="arrow-right">
                                    <path id="Vector" d="M22.5365 10.0238L15.4731 17.3588C14.639 18.2251 13.274 18.2251 12.4398 17.3588L5.37646 10.0238" stroke="#fff" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                            </g>
                        </svg>
                    </span>
                    <span>الحالات الانسانية</span>
                </p>
                <p>حالة رقم <span>{currentCase?.Critical_Case_Code}</span></p>
            </div>

            <div className={style.caseContent}>
                <Container>
                    <Row>
                        <Col className="d-flex flex-column justify-content-between my-5 my-md-0">
                            <div className={style.caseInfo}>
                                <div>
                                    <p>{currentCase?.Critical_Case_Name_AR}</p>

                                    <div style={{ position: 'relative' }}>
                                        <span className={style.shareIcon} onClick={() => setShareIsOpen(!shareIsOpen)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                                <path d="M18.8094 14C18.2395 14.0018 17.6767 14.1262 17.1591 14.3646C16.6414 14.6031 16.1812 14.9501 15.8094 15.382L10.7174 12.836C10.8401 12.2854 10.8401 11.7146 10.7174 11.164L15.8094 8.618C16.4272 9.31585 17.2704 9.77474 18.1919 9.91466C19.1133 10.0546 20.0547 9.86666 20.8518 9.38367C21.649 8.90067 22.2513 8.15323 22.5538 7.27165C22.8564 6.39008 22.8399 5.43028 22.5074 4.5596C22.1748 3.68891 21.5472 2.96255 20.7339 2.50716C19.9207 2.05178 18.9735 1.89623 18.0573 2.06766C17.1412 2.23908 16.3143 2.7266 15.7207 3.44522C15.1272 4.16384 14.8047 5.06797 14.8094 6C14.8099 6.28117 14.8408 6.56146 14.9014 6.836L9.8094 9.382C9.27558 8.76534 8.56599 8.3266 7.77577 8.12461C6.98555 7.92261 6.15246 7.96702 5.38819 8.25187C4.62392 8.53672 3.965 9.0484 3.49975 9.71832C3.03449 10.3882 2.78516 11.1844 2.78516 12C2.78516 12.8156 3.03449 13.6118 3.49975 14.2817C3.965 14.9516 4.62392 15.4633 5.38819 15.7481C6.15246 16.033 6.98555 16.0774 7.77577 15.8754C8.56599 15.6734 9.27558 15.2347 9.8094 14.618L14.9014 17.164C14.8408 17.4385 14.8099 17.7188 14.8094 18C14.8094 18.7911 15.044 19.5645 15.4835 20.2223C15.9231 20.8801 16.5478 21.3928 17.2787 21.6955C18.0096 21.9983 18.8138 22.0775 19.5898 21.9231C20.3657 21.7688 21.0784 21.3878 21.6378 20.8284C22.1972 20.269 22.5782 19.5563 22.7325 18.7804C22.8869 18.0044 22.8077 17.2002 22.5049 16.4693C22.2022 15.7384 21.6895 15.1137 21.0317 14.6741C20.3739 14.2346 19.6005 14 18.8094 14Z" fill="#257B72" />
                                            </svg>
                                        </span>

                                        {shareIsOpen ?
                                            <>
                                                <div className={style.shareDropdown}>
                                                    <span className={style.shareIcons} onClick={() => handleShareOnFb()}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px"
                                                            height="24px">
                                                            <path
                                                                d="M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2 C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z" />
                                                        </svg>
                                                    </span>
                                                    <span className={style.shareIcons} onClick={() => handleShareOnTwitter()}>
                                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                                            preserveAspectRatio="xMidYMid meet">

                                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                                fill="#000000" stroke="none">
                                                                <path d="M75 5093 c10 -16 448 -653 973 -1418 525 -765 955 -1395 955 -1400 0
                                                -6 -431 -512 -959 -1126 -527 -613 -965 -1123 -973 -1132 -13 -16 1 -17 206
                                                -17 l220 0 849 987 c467 542 854 988 859 989 6 2 316 -442 689 -986 l679 -990
                                                744 0 c705 0 743 1 734 18 -5 9 -460 672 -1010 1472 -550 800 -1001 1457
                                                -1001 1460 0 4 418 492 929 1085 l928 1080 -215 3 c-177 2 -218 0 -231 -12 -9
                                                -8 -374 -431 -811 -939 -437 -509 -798 -923 -802 -921 -4 3 -296 425 -649 939
                                                l-642 935 -745 0 -744 0 17 -27z m2815 -2529 c855 -1224 1557 -2229 1558
                                                -2235 3 -6 -118 -8 -334 -7 l-338 3 -1558 2228 c-857 1226 -1558 2231 -1558
                                                2233 0 2 152 4 338 4 l337 -1 1555 -2225z"/>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                    <span className={style.shareIcons} onClick={() => handleShareOnWhatsapp()}>
                                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt"
                                                            height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                                                            preserveAspectRatio="xMidYMid meet">
                                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                                fill="#000000" stroke="none">
                                                                <path d="M2290 4945 c-1101 -136 -1967 -1020 -2081 -2124 -17 -163 -7 -473 21
                                        -630 43 -247 115 -465 227 -688 l56 -112 -167 -611 c-92 -337 -166 -613 -164
                                        -614 2 -2 282 71 623 161 496 131 625 162 645 154 14 -5 79 -34 145 -64 214
                                        -97 439 -161 676 -193 165 -22 454 -22 614 0 526 74 995 306 1365 676 194 193
                                        323 370 445 611 110 217 188 454 232 704 25 146 25 577 0 730 -43 259 -110
                                        466 -223 695 -323 651 -919 1115 -1632 1270 -222 48 -553 63 -782 35z m625
                                        -414 c287 -53 525 -149 760 -306 402 -269 697 -686 814 -1148 86 -340 79 -726
                                        -18 -1053 -158 -528 -533 -973 -1025 -1213 -286 -140 -550 -201 -871 -201
                                        -368 0 -693 89 -1026 281 l-56 32 -368 -97 c-202 -53 -370 -95 -371 -93 -2 2
                                        39 160 92 352 52 192 96 356 96 364 1 9 -25 59 -57 111 -472 768 -348 1780
                                        295 2420 320 318 726 514 1180 570 123 15 429 4 555 -19z">
                                                                </path>
                                                                <path d="M1641 3658 c-57 -28 -163 -153 -207 -245 -87 -180 -85 -408 5 -618
                                        110 -259 399 -627 684 -871 200 -171 367 -272 612 -368 251 -98 299 -109 465
                                        -109 131 0 152 2 212 25 100 38 175 85 249 158 58 57 70 75 89 135 23 76 37
                                        197 26 239 -5 22 -34 40 -176 111 -260 130 -365 175 -409 175 -37 0 -43 -4
                                        -87 -62 -101 -133 -194 -236 -218 -242 -29 -7 -86 14 -217 80 -213 106 -386
                                        249 -535 440 -81 104 -154 222 -154 250 0 11 38 70 84 130 90 117 116 161 116
                                        194 0 11 -20 66 -43 123 -24 56 -72 172 -107 257 -44 106 -74 165 -96 188
                                        l-32 32 -108 0 c-91 0 -116 -4 -153 -22z"></path>
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </div>

                                                <div className={style.shareOverlay} onClick={() => setShareIsOpen(!shareIsOpen)}></div>
                                            </>
                                            : null}
                                    </div>
                                </div>

                                <div>
                                    <p>رقم الحالة</p>
                                    <p>{currentCase?.Critical_Case_Code}</p>
                                </div>

                                <p>{currentCase?.Critical_Case_Details_AR}</p>
                            </div>

                            <div className={style.moneyAmount}>
                                <div className={style.amount}>
                                    <p>مبلغ الدين</p>
                                    <p><span>{nf.format(currentCase?.Critical_Case_Donation_Value)}</span> درهم</p>
                                </div>

                                <div className={style.loader}>
                                    <p>التبرعات</p>
                                    <p>{currentCase?.Critical_Case_Payment_Value ? nf.format(currentCase?.Critical_Case_Payment_Value) : 0}</p>
                                    <div>
                                        <span style={{ width: loaderWidth }}></span>
                                    </div>
                                </div>

                                <div className={style.remaining}>
                                    <p>المتبقي</p>
                                    <p><span>{nf.format(currentCase?.Critical_Case_Donation_Value - currentCase?.Critical_Case_Payment_Value)}</span> درهم</p>
                                </div>
                            </div>
                        </Col>

                        <Col>
                            <DonateCard />
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row className={style.carouselDiv}>
                    <Col xs={12}>
                        <p className={style.carouselTitle}>الحالات الانسانية</p>
                    </Col>

                    <Col xs={12}>
                        <Carousel
                            className={style.carousel}
                            indicators={false}
                            prevIcon={
                                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="vuesax/linear/arrow-right">
                                        <g id="arrow-right">
                                            <path id="Vector" d="M22.5365 10.0238L15.4731 17.3588C14.639 18.2251 13.274 18.2251 12.4398 17.3588L5.37646 10.0238" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                    </g>
                                </svg>
                            }
                            nextIcon={
                                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="vuesax/linear/arrow-right">
                                        <g id="arrow-right">
                                            <path id="Vector" d="M22.5365 10.0238L15.4731 17.3588C14.639 18.2251 13.274 18.2251 12.4398 17.3588L5.37646 10.0238" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                    </g>
                                </svg>
                            }
                        >
                            {onMobile ?
                                cases.map((data, index) => {
                                    return (
                                        <Carousel.Item key={index} className="px-2">
                                            <CaseCard data={data} />
                                        </Carousel.Item>
                                    );
                                })
                                : cases?.reduce(reduceCases, []).map((item, index) => (
                                    <Carousel.Item key={index}>
                                        <Row className="justify-content-center">
                                            {item.map((data, index) => {
                                                return (
                                                    <Col md={4} key={index}>
                                                        <CaseCard data={data} />
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </Carousel.Item>
                                ))
                            }
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default CaseDonationContent